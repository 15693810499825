export const environment = {
  production: false,
  server: '/api',
  subscription: 'ws://api',
  iam: {
    url: 'https://signin.test.access.pwc.com/openam',
    authenticationPortal: '/oauth2/realms/venturessso/authorize',
    logoutPortal: '/XUI/?realm=%2Fventuressso#logout/&goto=',
    redirectUri: 'https://portal.dev.bookkeepingconnect.pwc.com',
    clientID: 'aspen',
    responseType: 'code',
    logoutDuration: '60',
    scope: 'ventures%20openid',
  },
  useMinus: false,
  name: 'dev',
  hideToolTips: false,
  cacheTime: 0,
  expiresIn: 13 * 60 * 1000,
  displaySNowWidget: true,
  pendoApi: '2a6fc0f4-6ed0-435c-78c0-d88218815803',
  internalDashboardUrl:
    'https://internal-dashboard.stage.insightsofficer.pwc.com/',
  bkcApi: 'AIzaSyAK1RihsmCSwZ-n4dH4C4XC5qX121clM4s',
  fastLinkURL: 'https://aggregationstage.pwc.com/authenticate/pwcagg/fastlink',
  termsOfUseUrl: 'https://productcentral.products.pwc.com/legal/Terms-of-Use',
};
