<ap-footer [content]="footerContent" [type]="'text'" class="g-col-12"></ap-footer>
<div class="ap-footer-policy ap-mb-spacing-5 ap-pb-spacing-5">
  <a class="ap-footer-link file-link" target="_self" (click)="openPrivacy()" tabindex="0"
    (keyup.enter)="openPrivacy()">Privacy Statement
  </a>
  <span class="ap-mx-spacing-4">|</span>
  <a class="ap-footer-link file-link" target="_blank" (click)="openTerms()" tabindex="0"
    (keyup.enter)="openTerms()">Terms of Use
  </a>
  <span class="ap-mx-spacing-4">|</span>
  <a class="file-link ap-footer-link cookie-settings" (click)="triggerCookieSetting()" tabindex="0">Cookie settings
  </a>
</div>
