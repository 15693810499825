import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AuthService, EXPIRED_KEY } from '@core';
import { TIMEOUT } from '@core/services/auth.service';
import { DomoDashboardService } from '@core/services/domo-dashboard.service';
import { StyleControlService } from '@core/services/style-control.service';
import { UtilService } from '@core/services/util.service';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@env';
import {
  DEACTIVATION_DIALOG_MODAL_ID,
  DeactivationDialogComponent,
} from '@shared/component/deactivation-dialog/deactivation-dialog.component';
import {
  TIMEOUT_DIALOG_MODAL_ID,
  TimeoutDialogComponent,
} from '@shared/component/timeout-dialog/timeout-dialog.component';
import { LicenseManager } from 'ag-grid-enterprise';
import { get } from 'lodash';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { AG_GRID_KEY } from './const';
import { UnsupportedPageComponent } from './routes/unsupported-page/unsupported-page.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, UnsupportedPageComponent, CommonModule],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly SMALL_SCREEN_SIZE = 768;
  displaySNowWidget = environment.displaySNowWidget;

  private readonly subs: Subscription[] = [];

  isNormalPage = false;

  constructor(
    private readonly authService: AuthService,
    private readonly utilService: UtilService,
    private readonly router: Router,
    private readonly styleControlService: StyleControlService,
    private readonly DomoDashboardService: DomoDashboardService,
  ) {
    this.dataDogInit();
    this.licenseInit();
    this.createShadowAnchor();
  }

  ngOnInit() {
    this.subs.push(
      this.authService.checkedSession$.subscribe(() => {
        this.utilService
          .openModal(TimeoutDialogComponent, TIMEOUT_DIALOG_MODAL_ID)
          .subscribe((data) => {
            if (data && typeof data === 'string') {
              if (data === 'logout') {
                this.authService.logout();
              }
              this.authService.refresh();
            }
          });
      }),
      this.authService.deactivation$.pipe(skip(1)).subscribe((userInfo) => {
        if (userInfo) {
          this.utilService
            .openModal(
              DeactivationDialogComponent,
              DEACTIVATION_DIALOG_MODAL_ID,
              {
                data: userInfo,
              },
            )
            .subscribe(() => this.authService.logout());
        }
      }),
      this.router.events.subscribe((v) => {
        if (v instanceof NavigationEnd) {
          const element = document.querySelector('aside') as HTMLInputElement;
          element.focus();
        }
      }),
      this.utilService.getStorageObservable().subscribe((value) => {
        this.handleMask();
      }),
    );
    this.keydownSwitch();
    window.addEventListener('resize', this.handleMask.bind(this));
  }

  keydownSwitch() {
    document.onkeydown = (key: any) => {
      const nodeName = key.target.nodeName?.toLowerCase() || '';
      const roleName = key.target.role?.toLowerCase() || '';
      if (
        key.keyCode === 84 &&
        !key.ctrlKey &&
        !key.shiftKey &&
        !key.altKey &&
        !key.metaKey &&
        roleName !== 'textbox' &&
        !this.isExcludedNode(nodeName) &&
        this.authService.isLogin() &&
        !this.isLogout()
      ) {
        this.themeChange();
      }
    };
  }

  isLogout(): boolean {
    const expiresIn = JSON.parse(localStorage.getItem(EXPIRED_KEY));
    const diff = new Date(expiresIn).getTime() - new Date().getTime();
    if (diff - TIMEOUT <= 0) {
      return true;
    }
    return false;
  }

  themeChange(): void {
    if (this.styleControlService.dataMode === 'dark') {
      this.styleControlService.dataMode = 'light';
    } else {
      this.styleControlService.dataMode = 'dark';
    }
  }

  isExcludedNode(nodeName: string) {
    const excludedNodes = [
      'input',
      'textarea',
      'ap-field',
      'ap-search',
      'ap-datepicker',
      'ap-timepicker',
      'ap-dropdown',
      'ap-pagination',
    ];
    return excludedNodes.includes(nodeName);
  }

  dataDogInit() {
    if (
      environment.name === 'stage' ||
      environment.name === 'perf' ||
      environment.name === 'prod'
    ) {
      datadogRum.init({
        applicationId: get(
          environment,
          'dd.applicationId',
          '91af9cec-f334-41d0-837a-1313b6d8b4ae',
        ),
        clientToken: get(
          environment,
          'dd.clientToken',
          'pubc557c1104aad89db270f5805fbbd151a',
        ),
        site: get(environment, 'dd.site', 'us3.datadoghq.com'),
        service: get(environment, 'dd.service', 'bookkeeping'),
        env: get(environment, 'dd.env', 'stage'),
        version: get(environment, 'dd.version', '1.0.0'),
        sampleRate: get(environment, 'dd.sampleRate', 100),
        premiumSampleRate: get(environment, 'dd.premiumSampleRate', 100),
        trackInteractions: get(environment, 'dd.trackInteractions', true),
      });
    }
  }

  licenseInit() {
    if (LicenseManager) {
      LicenseManager.setLicenseKey(AG_GRID_KEY);
    }
  }

  goMainContent() {
    const element = document.querySelector(`#maincontent`);
    element.scrollIntoView({
      behavior: 'smooth',
    });
    const top = document.body.scrollTop;
    document.getElementById(`skip-link`)?.blur();
    document.getElementById(`maincontent`)?.focus();
  }

  createShadowAnchor() {
    const shadowEle = document.createElement('div');
    shadowEle.id = 'ot-sdk-btn';
    shadowEle.className = 'ot-sdk-show-settings';
    shadowEle.style.display = 'none';
    document.getElementsByClassName('aspen')[0]?.appendChild(shadowEle);
  }

  handleMask() {
    const screenWidth = window.innerWidth;
    if (
      screenWidth < this.SMALL_SCREEN_SIZE &&
      this.utilService.getItem() !== 'true'
    ) {
      this.isNormalPage = false;
    } else {
      this.isNormalPage = true;
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
