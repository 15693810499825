<div class="ap-grid">
  <div class="g-col-12">
    <div class="ap-typography-heading-l app-text-heading">Notification Preferences</div>
    <div class="ap-typography-body ap-mt-spacing-5">
      <span>For </span>
      <span *ngIf="user" class="ap-font-weight-2">
        {{user.email}}</span>
    </div>
    <div class="ap-typography-heading-m app-text-heading ap-mt-spacing-5">
      <span>Assigned Customer Notifications</span>
    </div>
    <div [hidden]="isEmpty">
      <div class="flex-normal flex-justify-between align-items-center ap-mt-spacing-6 ap-mb-spacing-6">
        <div class="flex-normal flex-gap-4">
          <ap-checkbox [(ngModel)]="weeklyDigest" (onChange)="checkUpdated()">
            <span>Subscribe to Weekly Digests for all assigned Customers</span>
          </ap-checkbox>
        </div>
        <div class="flex-normal flex-gap-4 align-items-center">
          <ap-toggle [(ngModel)]="allNotification" (onChange)="selectAllNotifs($event)">
            <span>Select all notifications</span>
          </ap-toggle>
          <ap-button [btnType]="'primary'" [label]="'Save'" [disabled]="!isUpdated" (onClick)="save()">
          </ap-button>
        </div>
      </div>
      <ag-grid-angular class="ag-theme-quartz" [rowData]="preferences" [rowSelection]="'single'"
        [defaultColDef]="defaultColDef" [columnDefs]="colDefs" [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
      <app-table-pagination [count]="rowCount" [offset]="offset" [limitValue]="limit"
        (limitChange)="onLimitChange($event)" (pageChange)="onPageChange($event)">
      </app-table-pagination>
    </div>
    <app-table-empty [hidden]="!isEmpty" [tip]="'No Customers yet.'"></app-table-empty>
  </div>
</div>
