<div class="flex-normal full-height pre-login-container">
  <div class="left-part flex-normal flex-direction-column flex-justify-between">
    <div class="login-logo"></div>
    <div class="main-content-ontainer flex-normal flex-1 flex-justify-center max-height-overflow">
      <ng-container *ngIf="showPositionContainer; else noContainer">
        <div [class.position-container]="showPositionContainer">
          <router-outlet></router-outlet>
        </div>
      </ng-container>
      <ng-template #noContainer>
        <router-outlet></router-outlet>
      </ng-template>
    </div>
    <app-footer class="login-footer"></app-footer>
  </div>
  <div class="right-part">
    <div class="login-wrapper"></div>
  </div>
</div>
