import { EventEmitter, Injectable } from '@angular/core';
import { PanelInfo } from '@core/gql/comments-notes.gql';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmitService {
  public goBackEvent = new EventEmitter();
  public goBack() {
    this.goBackEvent.emit();
  }
  public switchTenant$: BehaviorSubject<{
    type: string;
    tenantKey: number;
  }> = new BehaviorSubject({ type: '', tenantKey: 0 });
  // When customer user view the Dashboard, global tenant toggle will hide, otherwise be shown. Default [true] means to be shown.
  public canCustomerUserViewGlobalTenantToggle$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  // Show the comment feeds panel on the right side in front of the screen. Default [true] means to be hidden.
  public showNotificationsPanel$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public showCommentPanel$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public panelToggle$: BehaviorSubject<PanelInfo> = new BehaviorSubject({
    panelType: null,
    toggle: false,
    tenantKey: null,
    taskInfo: null,
  });

  public loadingAPICount = 0;

  constructor() {}

  togglePanel(panelInfo: PanelInfo) {
    this.panelToggle$.next(panelInfo);
  }
  /**
   * setTimeout here is to avoid NGX 100 Error
   */
  startLoading() {
    setTimeout(() => {
      this.loadingAPICount++;
    });
  }
  endLoading() {
    setTimeout(() => {
      this.loadingAPICount--;
    });
  }
  finalEndLoading() {
    setTimeout(() => {
      this.loadingAPICount = 0;
    });
  }
}
