import { Component } from '@angular/core';
import { ButtonModule } from '@appkit4/angular-components/button';
import { UtilService } from '@core';

@Component({
  selector: 'app-unsupported-page',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './unsupported-page.component.html',
  styleUrl: './unsupported-page.component.scss',
})
export class UnsupportedPageComponent {
  constructor(private utilService: UtilService) {}
  onClickButton() {
    this.utilService.setItem('true');
  }
}
