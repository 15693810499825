import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@appkit4/angular-components/button';
import { BadgeModule } from '@appkit4/angular-components/badge';

@Component({
  selector: 'app-info-modal',
  standalone: true,
  imports: [CommonModule, ButtonModule, BadgeModule],
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent {
  @Input() title: string;
  @Input() cancelText: string = 'Cancel';
  @Input() saveText: string = 'Save';
  @Input() btnType: string = 'primary';
  @Input() hideCancel: boolean = false;
  @Input() hideCross: boolean = false;
  @Input() hasBadge: boolean = false;
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() cancelModal: EventEmitter<any> = new EventEmitter();
}
