import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ButtonModule } from '@appkit4/angular-components/button';
import {
  DrawerComponent,
  DrawerModule,
} from '@appkit4/angular-components/drawer';
import { FieldModule } from '@appkit4/angular-components/field';
import { ToggleModule } from '@appkit4/angular-components/toggle';
import { COMMENT_TYPE, EmitService } from '@core';
import { PanelInfo } from '@core/gql/comments-notes.gql';
import { ClickOutsideDirective } from '@shared/directive/click-outside/click-outside.directive';
import { DecodeUriPipe } from '@shared/pipe/decode-string/decode-uri.pipe';
import { CommentsPanelComponent } from '../comments-notes/comments-panel/comments-panel.component';
import { NotesPanelComponent } from '../comments-notes/notes-panel/notes-panel.component';
import { A11yModule } from '@angular/cdk/a11y';
@Component({
  selector: 'app-panel-sidenav',
  templateUrl: './panel-sidenav.component.html',
  styleUrls: ['./panel-sidenav.component.scss'],
  standalone: true,
  imports: [
    ClickOutsideDirective,
    CommonModule,
    CommentsPanelComponent,
    NotesPanelComponent,
    DrawerModule,
    DecodeUriPipe,
    ButtonModule,
    FieldModule,
    ToggleModule,
    A11yModule,
  ],
})
export class PanelSidenavComponent implements OnInit {
  @ViewChild('panel') panel: DrawerComponent;
  panelInfo: PanelInfo;
  PanelType = COMMENT_TYPE;
  clickOutsideEnabled = false;
  isVerticalDrawerVisible = false;

  constructor(
    public emitService: EmitService,
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.emitService.panelToggle$.subscribe((panelInfo) => {
      this.panelInfo = panelInfo;
      if (panelInfo.toggle) {
        this.isVerticalDrawerVisible = true;
        this.setPanelTitleStyle();
        setTimeout(() => {
          this.clickOutsideEnabled = true;
        }, 1000);
      } else {
        this.isVerticalDrawerVisible = false;
        this.clickOutsideEnabled = false;
      }
    });
  }

  setPanelTitleStyle() {
    const titleElement = this.el.nativeElement.querySelector(
      'app-panel-sidenav .ap-drawer-title',
    );
    const taskName = new DecodeUriPipe().transform(
      this.panelInfo?.taskInfo?.name,
    ) as string;
    this.renderer.setAttribute(titleElement, 'title', taskName);
  }

  closePanel() {
    this.emitService.togglePanel({
      panelType: null,
      toggle: false,
      tenantKey: null,
      taskInfo: null,
    });
    this.emitService.showCommentPanel$.next(false);
  }

  onClickedOutside(e: any) {
    if (
      !e.srcElement?.classList?.contains('panel-target') &&
      !e.srcElement?.className?.includes('userinfo')
    ) {
      this.closePanel();
    }
  }
}
